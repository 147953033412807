import React, { useMemo } from "react"
import HeaderV2 from "../headerv2/headerv2"
import Hero from "../../microcomponents/hero/hero"
import ClientLogos from "../../microcomponents/clientlogos/clientlogos"
import LeftRightImageText from "../../microcomponents/leftrightimagetext/leftrightimagetext"
import Button from "../../microcomponents/button/button"
import { ArrowIcon } from "../../microcomponents/icons"
import TopPredictions from "../../microcomponents/toppredictions/toppredictions"
import ClientResults from "../../microcomponents/clientresults/clientresults"
import Image from "../../components/image-component/image-new"
import Slider from "react-slick"
import { clusteringImages } from "../../utilities/clusteringimages"

export default function ResultsV2() {
  const settings = useMemo(() => {
    return {
      dots: true,
      infinite: true,
      autoplay: false,
      arrows: false,
      slidesToShow: 1,
      centerMode: true,
      centerPadding: "0px",
      responsive: [],
      dotsClass: "slick-dots-spz",
    }
  }, [])

  const SliderComponent = () => {
    return (
      <Slider className="multiple-tile-image-slider topone" {...settings}>
        <div className="image-tile">
          <Image
            sourceFolder={"spiralyze2024website"}
            lazyLoad={"true"}
            fallBackImage={`frame_1171274872.webp`}
            imgName={`frame_1171274872.webp`}
            cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
          />
        </div>
        <div className="image-tile">
          <Image
            sourceFolder={"spiralyze2024website"}
            lazyLoad={"true"}
            fallBackImage={`frame_1171275796.webp`}
            imgName={`frame_1171275796.webp`}
            cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
          />
        </div>
      </Slider>
    )
  }

  return (
    <div>
      <HeaderV2 version="v2" />
      <Hero
        version="v2"
        className="greeneclipse"
        breadcrumb={[
          { name: "Home", url: "/" },
          {
            name: "How <span class=''>it Works</span>",
            url: "/how-it-works/",
          },
          { name: "Results", url: null },
        ]}
        subHeading={null}
        heading="38% lift in 90 days <br class=''/> by leveraging <br class='hideipad'/> <span>proven winners.</span>"
        description="<p>Get fast results with a prediction <br class='hidedesktop hidetablet hideipad hidemobile'/> engine that identifies proven <br class='hidedesktop hidetablet hidemobile'/> winning <br class='hidedesktop hidetablet hideipad hidemobile'/> tests and a full-service <br class='hidedesktop hideipad hidemobile'/> team that gets tests into the <br class='hidedesktop hidetablet hidemobile'/> market.<br class='hideipad hidemobile'/> Results <br class='hidedesktop hidetablet hideipad hidemobile'/> guaranteed. </p>"
        tags={["Client Results", "Down-Funnel", "Results Guaranteed"]}
        heroImage="interior_hero.webp"
        sourceFolder="spiralyze2024website"
        cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
      />
      <ClientLogos version="v2" />
      <LeftRightImageText
        mainHeading="Average 38% lift in your first 90 <br class='hidedesktop hidemobile'/> days."
        subHeading="Client Results"
        sectionDescription="<p>Our clients average 38% more <br class='hidedesktop hidetablet hideipad hidemobile'/> conversions 90 days after kickoff. <br/> Using predictive A/B testing, we know what to test. And with our <br class='hidedesktop hidemobile'/> full-service model, you get more tests live and a <br class='hidetablet hideipad hidemobile'/> full pipeline.</p>"
        imageFolder="how_it_works"
        sectionImage="howitworksimage1.webp"
        reverse={true}
        innerClasses="pd-112"
        id="clientresults"
        imageComponent={<SliderComponent />}
      >
        <Button
          version={"v2"}
          href="/get-demo/"
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Get a Demo
        </Button>
      </LeftRightImageText>

      <LeftRightImageText
        mainHeading="Measure impact all the way <br class='hidedesktop hidemobile'/> through your funnel."
        subHeading="impact down-funnel"
        sectionDescription="<p>Measure impact beyond form fills all <br class='hidetablet hideipad'/> the way through your <br class='hidedesktop hidemobile'/> pipeline so that <br class='hidetablet hideipad'/> you see results in your core metrics <br class='hidetablet hideipad'/> and, <br class='hidedesktop hidemobile'/> ultimately, sales.</p><p>MQLs, SQLs, OPPs, and Deals Won.</p>"
        imageFolder="how_it_works"
        sectionImage="howitworksimage1.webp"
        reverse={true}
        innerClasses="reverse absolute-image-section"
        wrapperClasses="gray-bg pd-56-tablet-top"
        id="down-funnel"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"false"}
              fallBackImage={`image_49.webp`}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
              imgName={[
                {
                  imageName: "image_49.webp",
                  minBreakpoint: "1025px",
                },
                {
                  imageName: "frame_1171275798.webp",
                  minBreakpoint: "250px",
                },
              ]}
            />
          </>
        }
      >
        <Button
          version={"v2"}
          href="/get-demo/"
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Get a Demo
        </Button>
      </LeftRightImageText>
      <LeftRightImageText
        mainHeading="100% performance pricing."
        subHeading="Results Guaranteed"
        sectionDescription="<p>We tie fees 100% to performance. <br class='hidetablet hideipad'/>Nothing upfront, only <br class='hidedesktop hidemobile'/> pay after you <br class='hidetablet hideipad'/> get test wins.</p><p>You're the sole judge of lift.</p>"
        imageFolder="how_it_works"
        sectionImage="howitworksimage1.webp"
        reverse={true}
        innerClasses="absolute-image-section"
        wrapperClasses="pd-56-tablet-top"
        id="resultsguaranteed"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
              fallBackImage={`results_guaranteed_1441.webp`}
              imgName={[
                {
                  imageName: "results_guaranteed_1441.webp",
                  minBreakpoint: "1025px",
                },
                {
                  imageName: "results_guaranteed_769.webp",
                  minBreakpoint: "250px",
                },
              ]}
            />
          </>
        }
      >
        <Button
          version={"v2"}
          href="/get-demo/"
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Get a Demo
        </Button>
      </LeftRightImageText>
      <TopPredictions version="v2" />

      <ClientResults version="v2" />
    </div>
  )
}
