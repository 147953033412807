import * as React from "react"
import Layout from "../components/layout2023"
import Seo from "../components/seo2023"
import FooterV2 from "../componentsv2/footerv2/footerv2"
import ResultsV2 from "../componentsv2/resultsv2/resultsv2"

const Results = () => (
  <Layout nav={"hidden"} parentClass={`newtheme2024`}>
    <Seo title="Results" />
    <ResultsV2 />
    <FooterV2 version="v2" />
  </Layout>
)

export default Results
